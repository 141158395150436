import gql from 'graphql-tag'

export default gql`
{
  users {
    _id
    name
    scannedLabelCount
    checkInItemCount
    checkOutItemCount
  }
}
`
