import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ApolloClient from 'apollo-boost'
import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core'
import { indigo, pink, grey } from '@material-ui/core/colors'

import { registerObserver } from 'react-perf-devtool'
import { AuthProvider, AuthConsumer } from './contexts/AuthContext'
import LoginPage from './pages/LoginPage'

//import Index from './pages/index';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';
import './css/index.css'
registerObserver()


const theme = createMuiTheme({
    palette: {
        // type: 'dark',
        primary: indigo,
        secondary: pink,
    },
    typography: {
        useNextVariants: true,
    },
})

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}


const client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    // dataIdFromObject: o => o.id,
    // defaultOptions: defaultOptions
})

ReactDOM.render(
    <BrowserRouter>
        <ApolloProvider client={client}>
            <MuiThemeProvider theme={theme}>
                <AuthProvider>
                    <AuthConsumer>
                        {({ user }) => (
                            user ? (
                                // <CssBaseline />                         
                                <Switch>
                                    <Route component={App} />
                                </Switch>
                            )
                                : (
                                    <LoginPage />
                                )
                        )
                        }
                    </AuthConsumer>
                </AuthProvider>
            </MuiThemeProvider>
        </ApolloProvider>
    </BrowserRouter>,
    document.getElementById('root')
)

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
