import gql from 'graphql-tag'

const MutationLogin = gql`
mutation loginAdmin($email: String, $password : String) {
  loginAdmin(email : $email, password : $password) {
    token
  }
}
`

export default MutationLogin
