import React, { useState, useContext, useEffect } from 'react';
// import { withRouter } from 'react-router'
// import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom'




import _ from 'lodash'

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

//import FormControl from '@material-ui/core/FormControl';
//import GoogleAutoCompleteTextField from '../components/GoogleAutoCompleteTextField'


import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { MuiPickersUtilsProvider, TimePicker, DatePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';


import CreateEventMutation from '../graphql/mutations/CreateEventMutation';


import GoogleMapCreateEvent from '../components/NewMapTest';
import ImageUploadEditEvent from '../components/ImageUploadEditEvent'
import FroalaEditorComponent from '../components/FroalaEditorComponent'
import AuthContext from '../contexts/AuthContext'

import '../css/create-event.css';
import EventDetailQuery from '../graphql/queries/EventDetailQuery'
import DeleteDistanceFromEventIdMutation from '../graphql/mutations/DeleteDistanceFromEventIdMutation'
import EditEventMutation from '../graphql/mutations/EditEventMutation'
const ProvinceJson = require('../json/provinces.json');


const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '95%',
        color: 'black'
    },
}))









const EditEvent = () => {

    const history = useHistory()

    const [eventNameTH, setEventNameTH] = useState('')
    const [eventNameEN, setEventNameEN] = useState('')
    const [descriptionTH, setDescriptionTH] = useState('')
    const [descriptionEN, setDescriptionEN] = useState('')
    const [slug, setSlug] = useState('')
    const [eventDateStart, setEventDateStart] = useState(null)
    const [eventDateEnd, setEventDateEnd] = useState(null)
    const [bookableDateStart, setBookableDateStart] = useState(null)
    const [bookableDateEnd, setBookableDateEnd] = useState(null)
    const [priceStart, setPriceStart] = useState(null)
    const [priceEnd, setPriceEnd] = useState(null)
    const [organizer, setOrganizer] = useState('')
    const [latitude, setLatitude] = useState(13.7563309)
    const [longtitude, setLongtitude] = useState(100.5017651)
    const [coverPhotoUrl, setCoverPhotoUrl] = useState('')
    const [raceType, setRaceType] = useState('')
    const [location, setLocation] = useState('')
    const [keyword, setKeyword] = useState('')
    const [province, setProvince] = useState('')
    const [distance, setDistance] = useState([])
    const [day, setDay] = useState('')
    const [week, setWeek] = useState('')
    const [month, setMonth] = useState('')
    const [linkUrl, setLinkUrl] = useState('')
    const [showMap, setShowMap] = useState(false)
    const [isPublic, setIsPublic] = useState(false)
    const [howManyYear, setHowManyYear] = useState(null)
    const [thisYearth, setThisYearth] = useState(null)
    const [pinPost, setPinpost] = useState(false)
    const [locationTextShowTH, setLocationTextShowTH] = useState('')
    const [thumbnailPhotoUrl, setThumbnailPhotoUrl] = useState('')

    const [distanceDelete, setDistanceDelete] = useState([])

    const { user } = useContext(AuthContext)

    let { eventId } = useParams()
    const classes = useStyles()

    const query = useQuery(EventDetailQuery, {
        variables: {
            eventId: eventId
        }
    }, {
        fetchPolicy: 'no-cache'
    })

    const [editEvent] = useMutation(EditEventMutation)


    useEffect(() => {
        if (!query.loading && !query.error) {

            let distanceArray = []
            let distanceDeleteArray = []
            query.data.events[0].distance.map(distance => {
                distanceArray.push({ distance: distance.distance })
                distanceDeleteArray.push({ distanceTypeId: distance.distanceTypeId })
            })

            setDistanceDelete(distanceDeleteArray)

            setEventNameTH(query.data.events[0].eventNameTH)
            setDescriptionTH(query.data.events[0].descriptionTH)
            setEventNameEN(query.data.events[0].eventNameEN)
            setDescriptionEN(query.data.events[0].descriptionEN)
            setSlug(query.data.events[0].slug)
            setEventDateStart(query.data.events[0].eventDateStart)
            setEventDateEnd(query.data.events[0].eventDateEnd)
            setBookableDateStart(query.data.events[0].bookableDateStart)
            setBookableDateEnd(query.data.events[0].bookableDateEnd)
            setPriceStart(query.data.events[0].priceStart)
            setPriceEnd(query.data.events[0].priceEnd)
            setOrganizer(query.data.events[0].organizer)
            setCoverPhotoUrl(query.data.events[0].coverPhotoUrl)
            setRaceType(query.data.events[0].raceType)
            setKeyword(query.data.events[0].keyword)
            setProvince(query.data.events[0].provinceEN)
            setDistance(distanceArray)
            setDay(query.data.events[0].day)
            setWeek(query.data.events[0].week)
            setMonth(query.data.events[0].month)
            setLinkUrl(query.data.events[0].linkUrl)
            setIsPublic(query.data.events[0].isPublic)
            setHowManyYear(query.data.events[0].howManyYear)
            setThisYearth(query.data.events[0].thisYearth)
            setPinpost(query.data.events[0].isRecommend > 0 ? true : false)
            setThumbnailPhotoUrl(query.data.events[0].thumbnailPhotoUrl)
            setLocationTextShowTH(query.data.events[0].locationTextShowTH)


        }
    }, [query])

    useEffect(() => {
        query.refetch()
    }, [])


    if (query.loading || !query.data || slug === '') {
        return <div height="500px" style={{ textAlign: 'center', alignItems: 'center' }}>
            <br /><br /><br /><br /><br />
            <CircularProgress />

        </div>
    }


    const handleImageUpload = (upload) => {

        setCoverPhotoUrl(_.get(upload, 'original.url', null))

    }

    const handleImageUploadThumbnail = (upload) => {
        setThumbnailPhotoUrl(_.get(upload, 'original.url', null))
    }


    // onSubmit = (e) => {

    // }

    const getAddressFromGoogleMap = (address, markerPosition) => {

        this.setState({
            address,
            eventPosition: markerPosition
        })
    }


    const handleAddDistance = () => {

        setDistance([...distance, { distance: 0 }])
    }

    const handleRemoveDistance = idx => () => {

        setDistance(distance.filter((s, sidx) => idx !== sidx))
    }

    const handleDistanceChange = idx => evt => {

        const newDistance = distance.map((distance, sidx) => {
            if (idx !== sidx) return distance
            return { ...distance, distance: evt.target.value }
        })
        setDistance(newDistance)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const distanceArray = []
        let provinceTH
        distance.map(distance => {
            distanceArray.push({ distance: Number(distance.distance) })
        })

        const test = ProvinceJson.filter(provincejson => {
            return provincejson._id === province
        })

        if (test.length > 0) {
            provinceTH = test[0].name.th
            // console.log(provinceTH)
        }
        else {
            provinceTH = ""
        }


        editEvent({
            variables: {
                eventNameTH, eventNameEN, eventNameTHLowerCase: eventNameTH.toLowerCase(),
                eventNameENLowerCase: eventNameEN.toLowerCase(), slug, distanceCreate: distanceArray, distanceDelete, eventDateStart, eventDateEnd,
                bookableDateStart, bookableDateEnd, priceStart: priceStart ? Number(priceStart) : null, priceEnd: priceEnd ? Number(priceEnd) : null, organizer, keyword, raceType, linkUrl, coverPhotoUrl,
                descriptionTH, descriptionEN, provinceTH, provinceEN: province, eventAdminCreator: user.userId, eventId: query.data.events[0].eventId, day, week, month, isPublic, howManyYear: Number(howManyYear),
                thisYearth: Number(thisYearth), pinPost: pinPost ? 1 : 0, locationTextShowTH, thumbnailPhotoUrl
            }
        }).then(data => {
            alert("Edit Event Success!!")
            history.goBack()
        }).catch(e => {
            alert(e)
        })

    }
    // console.log(user)


    return (
        <div style={{ marginLeft: '10px' }}>
            <p style={{ marginTop: '20px' }} />
            <div style={{ textAlign: 'center', fontSize: '30px' }}>
                EDIT EVENT "{`${query.data.events[0].eventNameTH}`}"
                </div>

            {/* <div style={{ textAlign: 'center' }}>
                Create your event on thai.run
                </div> */}


            <p style={{ marginTop: '40px' }} />

            <Grid
                container
                // spacing={8}
                item
                xs={12}
                sm={12}
                md={12}

            >
                <form className={classes.form} onSubmit={e => handleSubmit(e)} >
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                Event Configuration
                                        </Typography>
                            {/* Start by adding the name, date and overview of your event. */}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControlLabel
                                control={
                                    <Switch checked={isPublic} onChange={e => setIsPublic(e.target.checked)} value="isPublic" />
                                }
                                label="เปิด Event เป็น Public หรือไม่"
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch checked={pinPost} onChange={e => { setPinpost(e.target.checked) }} value="pinPost" />
                                }
                                label="ปักหมุด Event นี้หรือไม่"
                            />
                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                รายละเอียดของ Event ภาษาไทย
                                        </Typography>
                            {/* Start by adding the name, date and overview of your event. */}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                name="eventNameTH"
                                fullWidth
                                label={"Event name TH"}
                                margin={"normal"}
                                variant="filled"
                                value={eventNameTH || ''}
                                onChange={e => setEventNameTH(e.target.value)}

                            />

                            <FroalaEditorComponent description={descriptionTH} setDescription={setDescriptionTH} />

                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                รายละเอียดของ Event ภาษาอังกฤษ
                                        </Typography>
                            {/* Start by adding the name, date and overview of your event. */}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                name="eventNameEN"
                                fullWidth
                                label={"Event name EN"}
                                margin={"normal"}
                                variant="filled"
                                value={eventNameEN || ''}
                                onChange={e => setEventNameEN(e.target.value)}

                            />

                            <FroalaEditorComponent description={descriptionEN} setDescription={setDescriptionEN} />

                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                รายละเอียดเกี่ยวกับวันที่ของ Event
                                        </Typography>
                            {/* Start by adding the name, date and overview of your event. */}
                        </Grid>
                        <Grid item xs={12} md={8}>


                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    // type="date"
                                    name="eventDateStart"
                                    format="yyyy-MM-dd"
                                    variant="filled"
                                    margin="normal"
                                    label="วันจัด Event"
                                    value={eventDateStart}
                                    onChange={date => setEventDateStart(date)}
                                />
                            </MuiPickersUtilsProvider>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    // type="date"
                                    name="eventDateEnd"
                                    format="yyyy-MM-dd"
                                    variant="filled"
                                    margin="normal"
                                    label="วันสิ้นสุด Event"
                                    value={eventDateEnd}
                                    onChange={date => setEventDateEnd(date)}
                                />
                            </MuiPickersUtilsProvider>
                            <br />

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    // type="date"
                                    name="bookableDateStart"
                                    format="yyyy-MM-dd"
                                    variant="filled"
                                    margin="normal"
                                    label="วันเริ่มการซื้อตั๋ว"
                                    value={bookableDateStart}
                                    onChange={date => setBookableDateStart(date)}
                                />
                            </MuiPickersUtilsProvider>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    // type="date"
                                    name="bookableDateEnd"
                                    format="yyyy-MM-dd"
                                    variant="filled"
                                    margin="normal"
                                    label="วันสิ้นสุดการซื้อตั๋ว"
                                    value={bookableDateEnd}
                                    onChange={date => setBookableDateEnd(date)}
                                />
                            </MuiPickersUtilsProvider>
                            <br />
                            <br />
                            ช่วงเวลาการจัดงานปกติของงาน <br />
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel htmlFor="filled-age-native-simple">วัน</InputLabel>
                                <Select
                                    native
                                    value={day || ''}
                                    onChange={e => setDay(e.target.value)}
                                    inputProps={{
                                        name: 'age',
                                        id: 'filled-age-native-simple',
                                    }}
                                >
                                    <option value="" />
                                    <option value={0}>อาทิตย์</option>
                                    <option value={1}>จันทร์</option>
                                    <option value={2}>อังคาร</option>
                                    <option value={3}>พุธ</option>
                                    <option value={4}>พฤหัส</option>
                                    <option value={5}>ศุกร์</option>
                                    <option value={6}>เสาร์</option>
                                </Select>
                            </FormControl>
                            &nbsp;
                            <FormControl variant="filled" className={classes.formControl} style={{ width: '200px' }}>
                                <InputLabel htmlFor="filled-age-native-simple">สัปดาห์ที่เท่าไหร่ของเดือน</InputLabel>
                                <Select
                                    native
                                    value={week || ''}
                                    onChange={e => setWeek(e.target.value)}
                                    inputProps={{
                                        name: 'age',
                                        id: 'filled-age-native-simple',
                                    }}
                                >
                                    <option value="" />
                                    <option value={0}>1</option>
                                    <option value={1}>2</option>
                                    <option value={2}>3</option>
                                    <option value={3}>4</option>
                                </Select>
                            </FormControl>
                            &nbsp;
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel htmlFor="filled-age-native-simple">เดือน</InputLabel>
                                <Select
                                    native
                                    value={month || ''}
                                    onChange={e => setMonth(e.target.value)}
                                    inputProps={{
                                        name: 'age',
                                        id: 'filled-age-native-simple',
                                    }}
                                >
                                    <option value="" />
                                    <option value={0}>มกราคม</option>
                                    <option value={1}>กุมภาพันธ์</option>
                                    <option value={2}>มีนาคม</option>
                                    <option value={3}>เมษายน</option>
                                    <option value={4}>พฤษภาคม</option>
                                    <option value={5}>มิถุนายน</option>
                                    <option value={6}>กรกฎาคม</option>
                                    <option value={7}>สิงหาคม</option>
                                    <option value={8}>กันยายน</option>
                                    <option value={9}>ตุลาคม</option>
                                    <option value={10}>พฤศจิกายน</option>
                                    <option value={11}>ธันวาคม</option>
                                </Select>
                            </FormControl>
                            &nbsp;
                            <br />
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <TextField
                                        type="number"
                                        name="howManyYear"
                                        fullWidth
                                        label={"Event จัดมากี่ปีแล้ว"}
                                        margin={"normal"}
                                        variant="filled"
                                        value={howManyYear || ''}
                                        onChange={e => setHowManyYear(e.target.value)}

                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        type="number"
                                        name="thisYearth"
                                        fullWidth
                                        label={"Event จัดปีนี้เป็นปีที่เท่าไหร่"}
                                        margin={"normal"}
                                        variant="filled"
                                        value={thisYearth || ''}
                                        onChange={e => setThisYearth(e.target.value)}
                                    />
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                Slug ของ Event
                                        </Typography>
                            {/* Start by adding the name, date and overview of your event. */}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                name="slug"
                                fullWidth
                                label={"Slug"}
                                margin={"normal"}
                                variant="filled"
                                value={slug || ''}
                                onChange={e => setSlug(e.target.value)}

                            />
                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                ราคาการสมัครของ Event
                                        </Typography>
                            {/* Start by adding the name, date and overview of your event. */}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                type="number"
                                name="priceStart"
                                label={"ราคาเริ่มต้น"}
                                margin={"normal"}
                                variant="filled"
                                value={priceStart || ''}
                                onChange={e => setPriceStart(e.target.value)}

                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <TextField
                                name="priceEnd"
                                type="number"
                                multiline
                                label={"ราคาสูงสุด"}
                                margin={"normal"}
                                variant="filled"
                                onChange={e => setPriceEnd(e.target.value)}
                                value={priceEnd || ''}
                            />
                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                Organizer ของ Event
                                        </Typography>
                            {/* Start by adding the name, date and overview of your event. */}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                name="organizer"
                                fullWidth
                                label={"organizer"}
                                margin={"normal"}
                                variant="filled"
                                value={organizer || ''}
                                onChange={e => setOrganizer(e.target.value)}

                            />
                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                EVENT Type
                                </Typography>
                            Select the type that covers your event races
                            </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControl fullWidth>
                                <RadioGroup aria-label="gender" name="raceType" value={raceType} onChange={e => setRaceType(e.target.value)} >
                                    <FormControlLabel
                                        value="road"
                                        control={<Radio color="secondary" />}
                                        label="Road"
                                    />
                                    <FormControlLabel
                                        value="trail"
                                        control={<Radio color="secondary" />}
                                        label="Trail"
                                    />
                                    <FormControlLabel
                                        value="vr"
                                        control={<Radio color="secondary" />}
                                        label="Virtual Run"
                                    />
                                    <FormControlLabel
                                        value="other"
                                        control={<Radio color="secondary" />}
                                        label="Other"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                RACE DISTANCES
                                </Typography>
                            Select all the races distances that apply to your event.
                            </Grid>
                        <Grid item xs={12} md={8}>
                            {distance.map((distance, idx) => (
                                <Grid container alignItems="center" spacing={1} key={`distance${idx + 1}`}>
                                    <Grid item xs={8}>
                                        <TextField

                                            type="number"
                                            name={`distance${idx + 1}`}
                                            fullWidth
                                            label={`ระยะที่ ${idx + 1}`}
                                            margin={"normal"}
                                            variant="filled"
                                            value={distance.distance}
                                            onChange={handleDistanceChange(idx)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button fullWidth variant={"contained"} color={"primary"} onClick={handleRemoveDistance(idx)}>
                                            ลบ
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))}
                            <Button variant={"contained"} color={"primary"} onClick={handleAddDistance}>
                                เพิ่มระยะทางใหม่
                            </Button>
                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />

                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                EVENT LINKS
                                </Typography>
                            Add a link to your event website or registration pages.
                            </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                name="eventWebsite"
                                fullWidth
                                label={"Event Website"}
                                margin={"normal"}
                                variant="filled"
                                onChange={e => setLinkUrl(e.target.value)}
                                value={linkUrl || ''}
                            />
                            Enter the full url including https://

                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                Keyword ในการ Search
                                </Typography>
                            {/* Add a link to your event website or registration pages. */}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                name="keyword"
                                fullWidth
                                label={"Keyword"}
                                margin={"normal"}
                                variant="filled"
                                onChange={e => setKeyword(e.target.value)}
                                value={keyword || ''}
                            />
                            ใส่ Keyword ที่ทำให้สามารถ Search เจอจากการค้นหา

                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                EVENT LOCATION
                                </Typography>
                            Setting an accurate location helps your event get found in relevant searches
                            </Grid>
                        <Grid item xs={12} md={8}>
                            <br />
                            <FormControl variant="filled" className={classes.formControl} style={{ width: '200px' }}>
                                <InputLabel htmlFor="filled-age-native-simple">จังหวัด</InputLabel>
                                <Select
                                    native
                                    value={province || ''}
                                    onChange={e => setProvince(e.target.value)}

                                    inputProps={{
                                        name: 'age',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <option key="0" value=""></option>
                                    {ProvinceJson.map(province => (
                                        <option key={province._id} value={province._id}>{province.name.th}</option>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                name="locationTextShowTH"
                                fullWidth
                                label={"สถานที่จัดงาน"}
                                margin={"normal"}
                                variant="filled"
                                value={locationTextShowTH || ''}
                                onChange={e => setLocationTextShowTH(e.target.value)}

                            />
                            <br />
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch checked={showMap} onChange={e => setShowMap(e.target.checked)} value="showMap" />
                                }
                                label="เปิด Advance Location setting"
                            />
                            <br />

                            {showMap ?
                                <React.Fragment>
                                    <TextField
                                        name="address"
                                        fullWidth
                                        label={"Map Location"}
                                        margin={"normal"}
                                        variant="filled"
                                    // value={this.state.address}
                                    // onChange={e => this.handleDataChange(e)}
                                    />

                                    {/* <GoogleAutoCompleteTextField /> */}

                                    {/* <Map
                                    textBoxSearch={this.state.address}
                                    action={this.getAddressFromGoogleMap}
                                    google={this.props.google}
                                    center={{ lat: 0, lng: 0 }}
                                    height='300px'
                                    zoom={8}
                                /> */}
                                    <GoogleMapCreateEvent
                                    // textBoxSearch={this.state.address}
                                    // action={this.getAddressFromGoogleMap}
                                    // markerPosition={this.state.markerPosition}
                                    />
                                </React.Fragment>
                                : null
                            }
                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Divider />
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                EVENT IMAGE
                                </Typography>
                            Add a header image that gets shown on the event page
                            </Grid>
                        <Grid item xs={12} md={8}>
                            <ImageUploadEditEvent
                                imageUrl={coverPhotoUrl}
                                onChange={handleImageUpload} />
                            {/* To ensure the image looks sharp, it should be at least 2000px wide */}
                        </Grid>
                    </Grid>
                    <p style={{ marginTop: '10px' }} />
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" color={"primary"}>
                                EVENT THUMBNAIL Image
                                </Typography>
                            Add a thumbnail image shown on the event page
                            </Grid>
                        <Grid item xs={12} md={4}>
                            <ImageUploadEditEvent
                                imageUrl={thumbnailPhotoUrl}
                                onChange={handleImageUploadThumbnail} />
                            {/* To ensure the image looks sharp, it should be at least 2000px wide */}
                        </Grid>
                    </Grid>
                    {/* <ImgDrop /> */}
                    <br /><br />
                    <FormControl margin={"normal"}>
                        <Grid container spacing={2} align="center" justify="center">
                            <Grid item xs={3}>
                                <Button type="submit" variant={"contained"} color={"primary"}>
                                    Save
                                 </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant={"contained"} color={"secondary"} onClick={e => history.goBack()}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </FormControl>

                    {/* <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone> */}


                </form>
            </Grid>

        </div >

    );
}


export default EditEvent