import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Typography, Paper, Avatar, FormControl, InputLabel, Input, Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'
import AuthContext from '../contexts/AuthContext'
import { useMutation } from '@apollo/react-hooks'

import MutationLogin from '../graphql/mutations/MutationLogin'

//import AuthContext from '../contexts/AuthContext'

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: spacing(3),
        marginRight: spacing(3),
        [breakpoints.up(400 + spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: spacing(2, 3, 3),
    },
    avatar: {
        margin: spacing(1),
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: spacing(1),
    },
    submit: {
        marginTop: spacing(3),
    },
}))

const LoginPage = () => {
    const classes = useStyles()
    const history = useHistory()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const { setToken } = useContext(AuthContext)

    const [loginMutation] = useMutation(MutationLogin, {
        onCompleted(data) {
            console.log(data)
            setError(false)
            setToken(data.loginAdmin.token)
            history.push('/')

        }
    })


    const handleSubmit = async (e) => {
        e.preventDefault()
        loginMutation({
            variables: {
                email: username,
                password: password
            }
        }).catch((err) => {
            setError(true)
        })

        // try {
        //     if (username === 'admin' && password === '123456') {
        //         const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZDBkZTdmMjkyNzY4ZjY4NDNjZWJlNjkiLCJyb2xlIjoiQWRtaW4iLCJuYW1lIjoiQWRtaW4iLCJpYXQiOjE1NjEyNzU2MjIsImV4cCI6MTU5MjgzMzIyMn0.Zf1ByiH5jeChdQ46DoxNStv49Ev2ePacg6AlV7umOaY'
        //         setToken(token)
        //         history.push('/')
        //     }
        // } catch (err) {
        //     console.error(err)
        // }
    }
    return (
        <main className={classes.main}>
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Where.Thai.Run ADMIN
        </Typography>
                <Typography variant="subtitle" style={{ color: "red", display: error ? "block" : 'none' }}>
                    username หรือ password ผิดพลาด
        </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="username">Username</InputLabel>
                        <Input
                            autoFocus
                            id="username"
                            name="username"
                            onChange={e => setUsername(e.target.value)}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            id="password"
                            name="password"
                            type="password"
                            onChange={e => setPassword(e.target.value)}
                        />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Login
          </Button>
                </form>
            </Paper>
        </main>
    )
}

export default LoginPage
