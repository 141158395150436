import gql from 'graphql-tag';



const CreateEventMutation = gql`
mutation CreateEvent($eventNameTH: String, $eventNameEN: String, $eventNameTHLowerCase: String, 
$eventNameENLowerCase: String, $slug: String!, $distance: [DistanceTypeCreateWithoutEventInput!],
$eventDate: DateTime,$eventDateStart: DateTime, $eventDateEnd: DateTime, $bookableDateStart: DateTime, $bookableDateEnd: DateTime,
 $priceStart: Int, $priceEnd: Int,
$organizer: String, $latitude: Float, $longtitude: Float, $location: String, $keyword: String, $raceType: String,
$linkUrl: String, $coverPhotoUrl: String, $descriptionTH: String, $descriptionEN: String, $provinceTH: String,
$provinceEN: String, $day: String, $week: String, $month: String, $isPublic: Boolean, $howManyYear: Int,
,$thisYearth: Int, $eventAdminCreator: String, $pinPost: Float, $locationTextShowTH: String, $thumbnailPhotoUrl: String ){
  createOneEvent(data:{
    eventNameTH: $eventNameTH, eventNameEN: $eventNameEN, eventNameTHLowerCase: $eventNameTHLowerCase,
    eventNameENLowerCase: $eventNameENLowerCase, slug : $slug, distance:{create: 
      $distance
    },eventDate: $eventDate, eventDateStart : $eventDateStart, eventDateEnd : $eventDateEnd
    bookableDateStart: $bookableDateStart,
    bookableDateEnd: $bookableDateEnd,
    priceStart: $priceStart, priceEnd: $priceEnd
    organizer: $organizer, latitude: $latitude, longtitude: $longtitude, location: $location,
    keyword: $keyword, raceType: $raceType,
    linkUrl: $linkUrl, coverPhotoUrl: $coverPhotoUrl, descriptionTH: $descriptionTH, descriptionEN: $descriptionEN,
    provinceTH : $provinceTH, provinceEN: $provinceEN, day: $day, week: $week, month: $month, isPublic: $isPublic,
    howManyYear: $howManyYear, thisYearth: $thisYearth,
    eventAdminCreator: {
      connect: {
        adminId: $eventAdminCreator
      }
    },
    lastUpdateAdmin: {
      connect: {
        adminId: $eventAdminCreator
      }
    }
    ,isRecommend: $pinPost, locationTextShowTH: $locationTextShowTH, thumbnailPhotoUrl: $thumbnailPhotoUrl 
  }    
  ){
    eventNameTH
    eventId
    distance{
      distance
      distanceTypeId
    }
  }
}`;


export default CreateEventMutation