import gql from 'graphql-tag';



const DeleteDistanceFromEventIdMutation = gql`
mutation DeleteDistanceFromEventId($eventId : String){
  deleteManyDistanceType(where: {
    event: {
      eventId: {
        equals : $eventId
      }
    }
  }){
    count
  }
}`;


export default DeleteDistanceFromEventIdMutation