import React, { useContext } from 'react'
// import { withRouter } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import {
    Typography
} from '@material-ui/core'
import AuthContext from '../contexts/AuthContext'

import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import ThaiRun from '../images/thai_run.png';



const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    header: {
        marginRight: '0 px',
        textAlign: 'right'
    }
});




const ComponentHeader = () => {

    const { user, removeToken } = useContext(AuthContext)
    const history = useHistory()

    const handleLogin = (event) => {
        // this.props.history.push(`${this.props.location.pathname}?modal=login`)

    }

    const handleSignup = (props) => {
        // this.props.history.push(`${this.props.location.pathname}?modal=signup`)
    }

    const handleLogout = (props) => {
        removeToken()
        history.push('/')

    }

    return (
        <React.Fragment>
            <Hidden mdUp><Link to="/"><img src={ThaiRun} style={{ height: '50px' }} /></Link></Hidden>
            <Hidden only={['xs', 'sm']}><Typography variant="h6" color="inherit" style={{ flexGrow: 1, color: 'white' }}>FINDING A CHALLENGE IS NO LONGER CHALLENGING</Typography></Hidden>
            <Typography color="inherit" align="right" style={{ textAlign: 'right', flexGrow: 1 }}>
            </Typography>
            {/* <Button color="inherit" onClick={event => this.handleSignup()} style={{ backgroundColor: 'gray' }} >
                SignUp
                    </Button> */}
            <Typography>
                {user.email}
            </Typography>
            <Button color="inherit" onClick={handleLogout} style={{ backgroundColor: 'red', marginLeft: '5px' }}>
                Logout
                    </Button>
        </React.Fragment>
    );

}

export default ComponentHeader;