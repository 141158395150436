import React, { useState, useRef, useEffect } from 'react';
// import ReactMapGL, { Marker } from 'react-map-gl';
import CountUp from 'react-countup'
// import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { grey } from '@material-ui/core/colors'





// import * as Geolocation from '../data/Geolocation.json';
// import Shoe from '../images/trail-running-shoe.svg';

// import EventModal from '../components/EventModal';
// import Cluster from '../components/TestMap';
import GoogleMapCluster from '../components/GoogleMapCluster';
import SearchBar from '../components/SearchBar';


const styles = theme => ({
    root: {
        position: 'relative',
        overflow: 'hidden',
    },
    appFrame: {
        width: 360,
        height: 360,
        backgroundColor: theme.palette.background.paper,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    button: {
        marginBottom: theme.spacing.unit,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
    fabMoveUp: {
        transform: 'translate3d(0, -46px, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeOut,
        }),
    },
    fabMoveDown: {
        transform: 'translate3d(0, 0, 0)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
    },
    snackbar: {
        position: 'absolute',
        width: '100%'

    },
    snackbarContent: {
        width: '100%',
        backgroundColor: 'black',
        color: 'white'
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    textGrey: {
        color: grey[200],
    }
});






class EventSearch extends React.Component {
    // const [viewport, setViewport] = useState({
    //     latitude: 13.2694113,
    //     longitude: 100.9604372,
    //     width: '1000px',
    //     height: '500px',
    //     zoom: 8
    // });

    componentDidMount() {
        this.props.action(null);
    }



    listItemClick = (e) => {

        alert('test');
    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.root}>
                    <SearchBar />
                    <GoogleMapCluster test="john" />

                </div>

                <Card className={classes.card} style={{ backgroundColor: 'black' }}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <List style={{ height: '90%' }}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar style={{ backgroundColor: 'gray', height: '100%' }}>
                                        <Typography variant="h2">
                                            <b>
                                                <CountUp
                                                    start={0}
                                                    delay={1}
                                                    end={1327}
                                                    duration={5}
                                                />
                                            </b>
                                        </Typography>

                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography component="span" variant="h4" className={classes.textGrey}>
                                                    RACES <br />FOUND
                                            </Typography>
                                            </React.Fragment>
                                        }
                                        secondary={null}
                                    />
                                </ListItem>
                            </List>
                        </CardContent>

                    </div>
                    <CardMedia
                        className={classes.cover}
                        image="/static/images/cards/live-from-space.jpg"
                        title="Live from space album cover"
                    />
                </Card>
                <p style={{ marginTop: '-50px' }} />
                <List className={classes.root}>
                    <ListItem alignItems="flex-start" style={{ backgroundColor: "#bbdb9b" }} divider button onClick={(e) => this.listItemClick(e)}>

                        <ListItemText style={{ marginLeft: '5%' }}
                            primary={
                                <div style={{ color: 'black' }}>
                                    🚂 Boost
                                <div style={{ size: 16 }}>
                                        John RUn
                                </div>

                                </div>
                            }
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" className={classes.inline} color="textPrimary">
                                        19 SEP 2019
                                </Typography>
                                </React.Fragment>
                            }
                        />
                        <Hidden smDown>
                            <div style={{ marginRight: '5%', marginTop: 'auto', marginBottom: 'auto' }}>
                                <Button variant="contained" size="large" color="secondary">
                                    View Event
                            </Button>
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div style={{ marginRight: '5%', marginTop: 'auto', marginBottom: 'auto' }}>
                                <ArrowForwardIosIcon />
                            </div>
                        </Hidden>

                    </ListItem>
                    <Divider style={{ borderColor: 'black' }} />
                    <ListItem alignItems="flex-start">

                        <ListItemText style={{ marginLeft: '5%' }}
                            primary="🚂 Brunch this weekend?"
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" className={classes.inline} color="textPrimary">
                                        Ali Connors
                                </Typography>
                                    {" — I'll be in your neighborhood doing errands this…"}
                                </React.Fragment>
                            }
                        />
                        <ListItemAvatar style={{ marginRight: '5%' }}>
                            <Button variant="contained" color="secondary">
                                View
                        </Button>
                        </ListItemAvatar>
                    </ListItem>
                    <Divider />
                    <ListItem alignItems="flex-start">

                        <ListItemText style={{ marginLeft: '5%' }}
                            primary="Brunch this weekend?"
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" className={classes.inline} color="textPrimary">
                                        Ali Connors
              </Typography>
                                    {" — I'll be in your neighborhood doing errands this…"}
                                </React.Fragment>
                            }
                        />
                        <ListItemAvatar style={{ marginRight: '5%' }}>
                            <Button variant="contained" color="secondary">
                                View
                    </Button>
                        </ListItemAvatar>
                    </ListItem>
                    <ListItem alignItems="center" style={{ backgroundColor: 'blue' }} button>
                        <ListItemText style={{ marginLeft: '5%' }}
                            primary={
                                <React.Fragment>
                                    <Typography component="span" className={classes.inline} color="textPrimary">
                                        <div align="center" style={{ fontWeight: 'bold' }}>
                                            LoadMore
                                </div>
                                    </Typography>
                                </React.Fragment>
                            }
                        />

                    </ListItem>
                </List>


            </div>
        )
    }
}

export default withStyles(styles)(EventSearch);