const config = {
    "navAnchor": "left",
    "navVariant": {
        "xs": "temporary",
        "sm": "temporary",
        "md": "persistent"
    },
    "navWidth": {
        "xs": 250,
        "sm": 256,
        "md": 256
    },
    "collapsible": {
        "xs": false,
        "sm": false,
        "md": false
    },
    "collapsedWidth": {
        "xs": 64,
        "sm": 64,
        "md": 64
    },
    "clipped": {
        "xs": true,
        "sm": true,
        "md": false
    },
    "headerPosition": {
        "xs": "relative",
        "sm": "relative",
        "md": "relative"
    },
    "squeezed": {
        "xs": false,
        "sm": false,
        "md": true
    },
    "footerShrink": {
        "xs": false,
        "sm": false,
        "md": false
    }
};

export default config