import React from "react";
import '../css/create-event.css';
import { Upload, Icon, message } from "antd";
import loadImage from 'blueimp-load-image'
import _get from 'lodash/get'
// import Button from '@material-ui/core/Button';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee, faTrash } from '@fortawesome/free-solid-svg-icons'
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';


function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

// const signatureUri = 'http://localhost:4000/upload/sign'

function beforeUpload(file) {
  // const isJPG = file.type === "image/jpeg";
  // if (!isJPG) {
  //   message.error("You can only upload JPG file!");
  // }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error("Image must smaller than 2MB!");
  // }
  // return true;
}



const resizeImg = async (file, { width, height }, quolity = 1) => {
  return new Promise((resolve, reject) => {
    loadImage(file, canvas => {
      if (canvas.type === 'error') {
        return Promise.reject(new Error('Incorrect Image'))
      }
      canvas.toBlob((blob) => {
        const result = new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now()
        })
        resolve(result)
      }, file.type, 1)
    }, {
      maxWidth: width,
      maxHeight: height,
      contain: true,
      orientation: true,
      canvas: true
    })
  })
}

const gcsUpload = async (file, onProgress) => {
  const res = await fetch(`${process.env.REACT_APP_IMAGE_UPLOAD_URL}?mimetype=${file.type}`, { method: 'POST' })
  const { signedUrl, fileUrl } = await res.json()
  return new Promise((resolve, reject) => {
    loadImage(file, canvas => {
      if (canvas.type === 'error') {
        return Promise.reject(new Error('Incorrect Image'))
      }
      const { width, height } = canvas
      canvas.toBlob((blob) => {
        const result = new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now()
        })
        const xhr = new XMLHttpRequest()
        xhr.open('PUT', signedUrl, true)
        xhr.onprogress = ({ loaded, total }) => {
          if (onProgress) {
            onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file)
          }
          // console.log({ progress })
        }
        xhr.onload = (response) => {
          //console.log(xhr.status, response)
          const status = xhr.status
          if (status === 200) {
            resolve({ url: fileUrl, size: { width, height } })
            // alert('File is uploaded')
          } else {
            reject(new Error('Something went wrong'))
            // alert('Something went wrong!')
          }
        }

        xhr.onerror = () => {
          reject(new Error('Something went wrong'))
        }
        xhr.setRequestHeader('Content-Type', file.type)
        xhr.setRequestHeader('x-goog-acl', 'public-read')
        xhr.send(file)
      }, file.type, 1)
    }, {
      orientation: true,
      canvas: true
    })
  })
}


class ImageUpload extends React.Component {
  state = {
    loading: false
  };

  customRequest = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials
  }) => {
    // TODO: use axios

    // const [originalFile, thumbnailFile] = await Promise.all([
    //   resizeImg(file, { width: MAX_DIMENSION, height: MAX_DIMENSION }),
    //   resizeImg(file, { width: MAX_THUMBNAIL_DIMENSION, height: MAX_THUMBNAIL_DIMENSION })
    // ])

    const [original, thumbnail] = await Promise.all([
      gcsUpload(file, onProgress),
      gcsUpload(file)
    ])
    onSuccess({
      original,
      thumbnail
    })
  }



  // handleChange = info => {
  //   if (info.file.status === "uploading") {
  //     this.setState({ loading: true });
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj, imageUrl =>
  //       this.setState({
  //         imageUrl,
  //         loading: false
  //       })
  //     );
  //   }
  // };

  handleChange = ({ file, fileList, event }) => {
    // console.log('change', { file })
    //this.setState({ loading: true })
    if (file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    const { onChange } = this.props
    if (file.status === 'done') {
      getBase64(file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
      // const { fileUrl } = file.response
      onChange(file.response)
      this.setState({ loading: false })
    }
  }

  click = e => {
    this.setState({
      imageUrl: ""
    });
    this.props.onChange(null)
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div>Click to upload cover photo for your event.</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;
    return (
      <div>
        <div className="show-image">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={this.handleChange}
            customRequest={this.customRequest}
          >
            {imageUrl ? (
              <img
                style={{ width: "100%", height: "200px" }}
                src={imageUrl}
                alt="avatar"
              />
            ) : (
                uploadButton
              )}
          </Upload>
          {imageUrl ? (
            <React.Fragment>
              <Fab aria-label="Delete" className="delete" onClick={e => this.click(e)}>
                <DeleteIcon />
              </Fab>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ImageUpload;
