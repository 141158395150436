import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import { graphql } from 'react-apollo'
// import queryString from 'query-string'
import { compose } from 'recompose';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import MenuIcon from '@material-ui/icons/Menu';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
    withStyles, Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import ListSubheader from '@material-ui/core/ListSubheader';

// import InfoIcon from '@material-ui/icons/Info';

// import ButtonBase from '@material-ui/core/ButtonBase';
// import { ExitToApp as LogoutIcon } from '@material-ui/icons'

import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';

// import Modal from '@material-ui/core/Modal';

// import { Root, Header, Nav, Content, Footer } from '../Layout';
//import SelectedListItem from '../components/navBar';
//import eventList from '../components/eventList';

import show_event from '../graphql/queries/show_event';
// import ComponentHeader from '../components/ComponentHeader';
import SearchBar from '../components/SearchBar';
//import ModalLogin from '../components/loginModal';
// import LoginModal from '../components/LoginModal';
// import SignupModal from '../components/SignupModal';
// import ComplexButton from '../components/ComplexButton';
// import App from './EventSearch';

import Test from '../images/thai_run.png'

import { AuthConsumer } from '../contexts/AuthContext'







const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    topBackground: {
        [theme.breakpoints.up('xs')]: {
            marginBottom: '50px'
        },
        [theme.breakpoints.up('sm')]: {
            marginBottom: '100px'
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '100px'
        },
    },
    stickyAppBar: {
        [theme.breakpoints.up('xs')]: {
            height: '120px'
        },
        [theme.breakpoints.up('sm')]: {
            height: '80px'
        },
        [theme.breakpoints.up('md')]: {
            height: '80px'
        },
    },
    mainContain: {
        [theme.breakpoints.up('xs')]: {
            // height: '141px'
        },
        [theme.breakpoints.up('sm')]: {
            // height: '80px'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    loadMore: {
        //paddingTop: theme.spacing(8),
        //paddingBottom: theme.spacing(8),
        //maxWidth: '1100px'
        [theme.breakpoints.up('xs')]: {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
    },
    // mainDiv: {
    //     [theme.breakpoints.up('md')]: {
    //         paddingLeft: '0px',
    //         paddingRight: '0px',
    //         maxWidth: '960px',
    //         marginLeft: 'auto',
    //         marginRight: 'auto'
    //     },
    // },
    marginGridItem: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '8px',
            marginRight: '8px'
        },
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    lists: {
        width: '100%'
    }
}));

const MainPage = () => {

    const classes = useStyles();

    // componentDidMount() {
    //     // this.props.action(0);
    //     //console.log(this.props.location.search);
    // }

    // componentWillMount() {
    //     //console.log(this.props.users);
    // }

    // componentDidUpdate(props) {



    // }


    // return (
    // const { classes } = this.props;

    //alert(process.env.REACT_APP_TEST);
    //console.log(classes);
    //console.log(this.state);
    //console.log(this.props.users);
    //console.log(this.props.location.pathname);
    // //console.log(queryString.parse(this.props.location.search));
    // const query = queryString.parse(this.props.location.search);
    // if (query.modal == 'login') {
    //     const modalOpen = true;
    //     console.log("true");
    // }
    // else {
    //     const modalOpen = false;
    //     console.log("false");
    // }

    return (
        <React.Fragment>
            {/* <AuthConsumer>
                    {({ user }) => (console.log(user))}
                </AuthConsumer> */}


            {/* content goes here */}
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                <Typography component="h1" variant="h1" gutterBottom align="center">
                    <img src={Test} style={{ width: '60%' }} />
                </Typography>

                <Typography component="h1" variant="h1" gutterBottom align="center" style={{ color: 'black' }}>
                    <b> FIND YOUR <br />NEXT RACE </b>
                </Typography>




                <p style={{ marginTop: '20px' }} />

                <SearchBar />


                <br /> <br />







                <ArrowForwardIosIcon /> <font style={{ fontSize: '25px' }}><b> Runner</b></font>

                <p style={{ marginTop: '10px' }} />

                <Grid container spacing={8} >
                    <Grid item xs={12} sm={6}>
                        <Card className={classes.card}>
                            <Link to="/events"><img src={Test} style={{ width: '100%' }} /></Link>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>

                    </Grid>

                </Grid>
            </div>
        </React.Fragment>







    );
}

export default compose(
    // graphql(show_event, { name: 'users' }),
    withStyles({ withTheme: true }),
)(MainPage)