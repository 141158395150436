import gql from 'graphql-tag';



const DeleteEventFromEventId = gql`
mutation DeleteDistanceFromEventId($eventId : String){
    deleteOneEvent(where: {
    eventId: $eventId
  }){
    eventNameTH
  }
}`;


export default DeleteEventFromEventId