// import React, { useState, useEffect } from 'react'
// import { AgGridReact } from "@ag-grid-community/react";
// import { AllCommunityModules } from "@ag-grid-community/all-modules";
// import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
// import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";


// const EventList = () => {

//     const [rowDatas, setRowDatas] = useState({ rowData: null })

//     useEffect(() => {
//         console.log(rowDatas)
//     }, [rowDatas])

//     const [info, setInfo] = useState({
//         modules: AllCommunityModules,
//         columnDefs: [
//             {
//                 headerName: "Athlete",
//                 field: "athlete"
//             },
//             {
//                 headerName: "Sport",
//                 field: "sport"
//             },
//             {
//                 headerName: "Age",
//                 field: "age",
//                 type: "numberColumn"
//             },
//             {
//                 headerName: "Year",
//                 field: "year",
//                 type: "numberColumn"
//             },
//             {
//                 headerName: "Date",
//                 field: "date",
//                 type: ["dateColumn", "nonEditableColumn"],
//                 width: 200
//             },
//             {
//                 headerName: "Medals",
//                 groupId: "medalsGroup",
//                 children: [
//                     {
//                         headerName: "Gold",
//                         field: "gold",
//                         type: "medalColumn"
//                     },
//                     {
//                         headerName: "Silver",
//                         field: "silver",
//                         type: "medalColumn"
//                     },
//                     {
//                         headerName: "Bronze",
//                         field: "bronze",
//                         type: "medalColumn"
//                     }
//                 ]
//             }
//         ],
//         defaultColDef: {
//             width: 150,
//             editable: true,
//             filter: "agTextColumnFilter"
//         },
//         defaultColGroupDef: { marryChildren: true },
//         columnTypes: {
//             numberColumn: {
//                 width: 83,
//                 filter: "agNumberColumnFilter"
//             },
//             medalColumn: {
//                 width: 100,
//                 columnGroupShow: "open",
//                 filter: false
//             },
//             nonEditableColumn: { editable: false },
//             dateColumn: {
//                 filter: "agDateColumnFilter",
//                 filterParams: {
//                     comparator: function (filterLocalDateAtMidnight, cellValue) {
//                         var dateParts = cellValue.split("/");
//                         var day = Number(dateParts[0]);
//                         var month = Number(dateParts[1]) - 1;
//                         var year = Number(dateParts[2]);
//                         var cellDate = new Date(year, month, day);
//                         if (cellDate < filterLocalDateAtMidnight) {
//                             return -1;
//                         } else if (cellDate > filterLocalDateAtMidnight) {
//                             return 1;
//                         } else {
//                             return 0;
//                         }
//                     }
//                 }
//             }
//         },
//         rowData: null
//     }
//     )
//     console.log(info.columnDefs)


//     const onGridReady = params => {
//         const gridApi = params.api;
//         const gridColumnApi = params.columnApi

//         const httpRequest = new XMLHttpRequest()
//         const updateData = data => {
//             setRowDatas({ rowData: data });
//             console.log("test")
//         }

//         httpRequest.open(
//             "GET",
//             "https://raw.githubusercontent.com/ag-grid/ag-grid/master/packages/ag-grid-docs/src/olympicWinnersSmall.json"
//         )
//         httpRequest.send();
//         httpRequest.onreadystatechange = () => {
//             if (httpRequest.readyState === 4 && httpRequest.status === 200) {
//                 updateData(JSON.parse(httpRequest.responseText));
//             }
//         }
//     }



//     return (
//         <div style={{ width: "100%", height: "100%" }}>
//             <div style={{ height: "100%", boxSizing: "border-box" }}>
//                 <div
//                     id="myGrid"
//                     style={{
//                         height: "100%",
//                         width: "100%"
//                     }}
//                     className="ag-theme-balham"
//                 >
//                     <AgGridReact
//                         modules={info.modules}
//                         columnDefs={info.columnDefs}
//                         defaultColDef={info.defaultColDef}
//                         defaultColGroupDef={info.defaultColGroupDef}
//                         columnTypes={info.columnTypes}
//                         rowData={rowDatas.rowData}
//                         floatingFilter={true}
//                         onGridReady={onGridReady}
//                     />

//                 </div>
//             </div>
//         </div>
//     )
// }

// export default EventList


// import React, { Component } from "react";
// import { render } from "react-dom";
// import { AgGridReact } from "ag-grid-react";
// import { AllCommunityModules } from "@ag-grid-community/all-modules";
// import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
// import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

// class EventList extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             modules: AllCommunityModules,
//             columnDefs: [
//                 {
//                     headerName: "Athlete",
//                     field: "athlete"
//                 },
//                 {
//                     headerName: "Sport",
//                     field: "sport"
//                 },
//                 {
//                     headerName: "Age",
//                     field: "age",
//                     type: "numberColumn"
//                 },
//                 {
//                     headerName: "Year",
//                     field: "year",
//                     type: "numberColumn"
//                 },
//                 {
//                     headerName: "Date",
//                     field: "date",
//                     type: ["dateColumn", "nonEditableColumn"],
//                     width: 200
//                 },
//                 {
//                     headerName: "Medals",
//                     groupId: "medalsGroup",
//                     children: [
//                         {
//                             headerName: "Gold",
//                             field: "gold",
//                             type: "medalColumn"
//                         },
//                         {
//                             headerName: "Silver",
//                             field: "silver",
//                             type: "medalColumn"
//                         },
//                         {
//                             headerName: "Bronze",
//                             field: "bronze",
//                             type: "medalColumn"
//                         }
//                     ]
//                 }
//             ],
//             defaultColDef: {
//                 width: 150,
//                 editable: true,
//                 filter: "agTextColumnFilter"
//             },
//             defaultColGroupDef: { marryChildren: true },
//             columnTypes: {
//                 numberColumn: {
//                     width: 83,
//                     filter: "agNumberColumnFilter"
//                 },
//                 medalColumn: {
//                     width: 100,
//                     columnGroupShow: "open",
//                     filter: false
//                 },
//                 nonEditableColumn: { editable: false },
//                 dateColumn: {
//                     filter: "agDateColumnFilter",
//                     filterParams: {
//                         comparator: function (filterLocalDateAtMidnight, cellValue) {
//                             var dateParts = cellValue.split("/");
//                             var day = Number(dateParts[0]);
//                             var month = Number(dateParts[1]) - 1;
//                             var year = Number(dateParts[2]);
//                             var cellDate = new Date(year, month, day);
//                             if (cellDate < filterLocalDateAtMidnight) {
//                                 return -1;
//                             } else if (cellDate > filterLocalDateAtMidnight) {
//                                 return 1;
//                             } else {
//                                 return 0;
//                             }
//                         }
//                     }
//                 }
//             },
//             rowData: null
//         };
//     }

//     onGridReady = params => {
//         this.gridApi = params.api;
//         this.gridColumnApi = params.columnApi;

//         const httpRequest = new XMLHttpRequest();
//         const updateData = data => {
//             this.setState({ rowData: data });
//         };

//         httpRequest.open(
//             "GET",
//             "https://raw.githubusercontent.com/ag-grid/ag-grid/master/packages/ag-grid-docs/src/olympicWinnersSmall.json"
//         );
//         httpRequest.send();
//         httpRequest.onreadystatechange = () => {
//             if (httpRequest.readyState === 4 && httpRequest.status === 200) {
//                 updateData(JSON.parse(httpRequest.responseText));
//             }
//         };
//     };

//     render() {
//         return (
//             <div style={{ width: "100%", height: "100%" }}>
//                 <div style={{ height: "100%", boxSizing: "border-box" }}>
//                     <div
//                         id="myGrid"
//                         style={{
//                             height: "100%",
//                             width: "100%"
//                         }}
//                         className="ag-theme-balham"
//                     >
//                         <AgGridReact
//                             modules={this.state.modules}
//                             columnDefs={this.state.columnDefs}
//                             defaultColDef={this.state.defaultColDef}
//                             defaultColGroupDef={this.state.defaultColGroupDef}
//                             columnTypes={this.state.columnTypes}
//                             rowData={this.state.rowData}
//                             floatingFilter={true}
//                             onGridReady={this.onGridReady}
//                         />
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// import React, { useState } from 'react'
// import { AgGridReact } from 'ag-grid-react';

// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';

// const EventList = () => {
//     // const [state, setState] = useState({
//     const state = {
//         columnDefs: [
//             { headerName: "Make", field: "make" },
//             { headerName: "Model", field: "model" },
//             { headerName: "Price", field: "price" }],
//         rowData: [
//             { make: "Toyota", model: "Celica", price: 35000 },
//             { make: "Ford", model: "Mondeo", price: 32000 },
//             { make: "Porsche", model: "Boxter", price: 72000 }]
//         // })
//     }


//     return (
//         <div className="ag-theme-balham" style={{ height: '100%', width: '100%' }}>
//             <AgGridReact
//                 columnDefs={state.columnDefs}
//                 rowData={state.rowData}>
//             </AgGridReact>
//         </div>
//     );
// }

import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress';
import ListAllEventQuery from '../graphql/queries/ListAllEventQuery'

import DeleteDistanceFromEventIdMutation from '../graphql/mutations/DeleteDistanceFromEventIdMutation'
import DeleteEventFromEventId from '../graphql/mutations/DeleteEventFromEventId'


import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const EventList = () => {
    const history = useHistory()

    const [columns, setColumns] = useState([{
        title: 'ชื่อ Event',
        field: 'eventNameTH',
        // customSort: (a, b) => a.name.length - b.name.length
    },
    { title: "Public", field: "isPublic", type: 'boolean' },
    { title: 'Slug', field: 'slug' },
    { title: 'วันเริ่ม Event', field: 'eventDateStart', type: 'date' },
    {
        title: 'ประเภทการวิ่ง',
        field: 'raceType',
        lookup: { road: 'Road', trail: 'Trail', vr: "VR" },
    },
    {
        title: 'เปลี่ยนแปลงล่าสุดเมื่อเวลา',
        field: 'lastUpdateTime',
        type: "datetime",
    }
    ]
    )

    const [dataTable, setDataTable] = useState([])
    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = useState({
        deleteId: null,
        deleteName: '',
        deleteIndex: null
    })

    const query = useQuery(ListAllEventQuery)

    const [deleteDistance] = useMutation(DeleteDistanceFromEventIdMutation)
    const [deleteEvent] = useMutation(DeleteEventFromEventId)



    const handleClickOpen = (rowData) => {
        setDeleteId({ deleteId: rowData.eventId, deleteIndex: rowData.tableData.id, deleteName: rowData.eventNameTH })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        // alert("datatable Update")
        // console.log(dataTable.length)
    }, [dataTable])

    useEffect(() => {
        if (!query.loading) {
            setDataTable(query.data.events)
        }

    }, [query])

    if (query.loading) {
        return <div style={{ textAlign: 'center', alignItems: 'center', verticalAlign: 'center', height: '100vh' }}>
            <br /><br /><br /><br />
            <CircularProgress />

        </div>
    }

    // console.log("rerender", dataTable.length)

    const deleteEventClick = async () => {
        // console.log(deleteId.deleteIndex)
        let dataTableTemporary = dataTable
        // console.log("data", data)
        // console.log("dataTableTemporary", dataTableTemporary.length)
        // console.log("deleteIdIndex", deleteId.deleteIndex)
        dataTableTemporary.splice(deleteId.deleteIndex, 1)
        // console.log(dataTableTemporary.length)
        setDataTable([...dataTableTemporary])
        // alert(deleteId.deleteIndex)
        deleteDistance({
            variables: {
                eventId: deleteId.deleteId
            }
        }).then(data => {
            deleteEvent({
                variables: {
                    eventId: deleteId.deleteId
                }
            }).then(data => {
                // let dataTableTemporary = dataTable
                // console.log("data", data)
                // alert(`${deleteId.deleteName} has been deleted!!!`)
                // dataTableTemporary.splice(deleteId.deleteIndex, 1)
                // setDataTable(dataTableTemporary)
                // alert(deleteId.deleteIndex)

            }).catch(e => {
                alert(e)
            })
        }).catch(e => {
            alert(e)
        })
        setOpen(false)
    }

    return (
        <div>
            <MaterialTable
                title="Event List"
                columns={columns}
                data={[...dataTable]}
                options={{
                    sorting: true,
                    filtering: true,
                    // paginationType: 'stepped',
                    pageSize: 10,
                    actionsColumnIndex: 10
                }}
                actions={[
                    {
                        icon: EditIcon,
                        tooltip: 'Edit Event',
                        onClick: (event, rowData) => { history.push(`/editEvent/${rowData.eventId}`) }
                    },
                    {
                        icon: DeleteForeverIcon,
                        tooltip: 'Delete Event',
                        onClick: (event, rowData, index) => handleClickOpen(rowData)
                    },
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: (e) => {
                            query.refetch()
                        },
                    }
                    // console.log(rowData.tableData.id)
                    // {
                    //   icon: 'delete',
                    //   tooltip: 'Delete User',
                    //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name)
                    // }
                ]}
            />




            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Delete Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {`Are you sure , you want to delete event "${deleteId.deleteName}"`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={deleteEventClick} color="primary">
                        Delete
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default EventList