import ReactDOM from "react-dom";
import React from "react";
import { compose, withProps, withHandlers, withState } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyB6LQ-qWuAuN2nV5wfxBW3jkVgBRppGKMY");
Geocode.enableDebug();

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyB6LQ-qWuAuN2nV5wfxBW3jkVgBRppGKMY&libraries=places",
        loadingElement: <div style={{ height: "100%" }} />,
        containerElement: <div style={{ height: "300px" }} />,
        mapElement: <div style={{ height: "100%" }} />
    }),
    withState('center', 'onCenterChange', { lat: 13.7563309, lng: 100.5017651 }),
    withScriptjs,
    withGoogleMap,
    withHandlers((props) => {
        const refs = {
            map: undefined,
        }

        return {
            onMapMounted: () => ref => {
                refs.map = ref
            },
            onCenterChanged: ({ onCenterChange }) => () => {
                //onCenterChange(refs.map.getCenter())
                props.onMapCenterChanged(refs.map.getCenter());
            }
        }
    })
)(props => (
    <GoogleMap
        defaultZoom={8}
        center={props.latlng.centerPosition}
        onClick={e => props.mapclick(e)}
        ref={props.onMapMounted}
        onCenterChanged={props.onCenterChanged}

    >

        {props.isMarkerShown && (
            <Marker
                position={{ lat: props.latlng.markerPosition.lat, lng: props.latlng.markerPosition.lng }}
                onClick={props.onMarkerClick}
            />
        )}
    </GoogleMap>
));

class GoogleMapCreateEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markerPosition:
            {
                lat: 13.7563309,
                lng: 100.5017651
            },
            centerPosition: {
                lat: 13.7563309,
                lng: 100.5017651
            },
            textBoxSearch: ''
        };
    }

    componentWillReceiveProps(props) {
        //console.log('componentWillReceiveProps', props.textBoxSearch);
        // console.log(componentWillReceiveProps', props.markerPosition)
        if (props.textBoxSearch !== this.state.textBoxSearch) {
            // Get latidude & lngitude from address.
            Geocode.fromAddress(props.textBoxSearch).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    //console.log(lat, lng);
                    this.setState({
                        markerPosition: {
                            lat: lat,
                            lng: lng
                        },
                        centerPosition: {
                            lat: lat,
                            lng: lng
                        }
                    })
                },
                error => {
                    console.error(error);
                }
            );
        }
    }

    handleMarkerClick = () => {
        //alert("test");
    }

    doAnAction = () => {
        //console.log("Im doing something");
        this.setState(state => ({
            la: this.state.lat + 1,
            lng: this.state.lng + 1,
        }));
    };

    mapclick = (e) => {
        //console.log(e);
        Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
            response => {
                // console.log(response);
                const address = response.results[0].formatted_address;
                this.setState({
                    markerPosition: {
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng()
                    },
                    textBoxSearch: address
                })
                this.props.action(address, this.state.markerPosition);

            },
            error => {
                console.error(error);
            }


        );

    }

    onMapCenterChanged = (latLng) => {
        //console.log(onMapCenterChanged.lat());
        this.setState({
            centerPosition: {
                lat: latLng.lat(),
                lng: latLng.lng()
            }
        })

    }



    render() {
        return (
            <div>
                <MyMapComponent
                    isMarkerShown={true}
                    onMarkerClick={this.handleMarkerClick}
                    latlng={this.state}
                    mapclick={this.mapclick}
                    onMapCenterChanged={this.onMapCenterChanged}
                />
            </div>
        );
    }
}

export default GoogleMapCreateEvent
