import React, { useState, useEffect } from 'react'

import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/froala_editor.min.js'

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/js/plugins/image.min.js'

import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/js/plugins/code_view.min.js'

import 'froala-editor/js/plugins/align.min.js'

import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/js/plugins/colors.min.js'

import 'froala-editor/css/plugins/draggable.min.css'
import 'froala-editor/js/plugins/draggable.min.js'

import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/js/plugins/emoticons.min.js'

import 'froala-editor/js/plugins/entities.min.js'

// import 'froala-editor/js/plugins/font_awesome.min.js'

import 'froala-editor/js/plugins/font_family.min.js'

import 'froala-editor/js/plugins/font_size.min.js'

import 'froala-editor/css/plugins/fullscreen.min.css'
import 'froala-editor/js/plugins/fullscreen.min.js'

import 'froala-editor/css/third_party/image_tui.min.css'
import 'froala-editor/js/third_party/image_tui.min.js'

import 'froala-editor/js/plugins/inline_class.min.js'

import 'froala-editor/js/plugins/inline_style.min.js'

import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/js/plugins/line_breaker.min.js'

import 'froala-editor/js/plugins/line_height.min.js'

import 'froala-editor/js/plugins/link.min.js'

import 'froala-editor/js/plugins/lists.min.js'

import 'froala-editor/js/plugins/paragraph_format.min.js'

import 'froala-editor/js/plugins/paragraph_style.min.js'

import 'froala-editor/css/plugins/quick_insert.min.css'
import 'froala-editor/js/plugins/quick_insert.min.js'

import 'froala-editor/js/plugins/quote.min.js'

import 'froala-editor/js/plugins/save.min.js'

import 'froala-editor/css/plugins/special_characters.min.css'
import 'froala-editor/js/plugins/special_characters.min.js'

import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/js/plugins/table.min.js'

import 'froala-editor/js/plugins/url.min.js'

import 'froala-editor/css/plugins/video.min.css'
import 'froala-editor/js/plugins/video.min.js'

import 'froala-editor/js/plugins/word_paste.min.js'

import FroalaEditor from 'react-froala-wysiwyg'

import 'codemirror/lib/codemirror.css'
import CodeMirror from 'codemirror/lib/codemirror.js'
import 'codemirror/mode/xml/xml.js'
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'


const FroalaEditorComponent = (props) => {
    // const [state, setState] = useState(props.description)
    // console.log("initialze", props.description)
    // console.log("state", state)
    // const handleChange = (e) => {

    //     setState(e)
    //     props.setDescription(e)

    // }

    // useEffect(() => {
    //     setState(props.description)
    // }, [props.description])

    // if (!props.description) {
    //     return (
    //         <div>

    //         </div>
    //     )
    // }

    return (
        < React.Fragment>
            <FroalaEditor
                tag='textarea'
                config={{
                    placeholderText: 'Edit Your Content Here!',
                    toolbarButtons: {
                        'moreText': {
                            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                        },
                        'moreParagraph': {
                            'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                        },
                        'moreRich': {
                            'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
                        },
                        'moreMisc': {
                            'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                            'align': 'right',
                            'buttonsVisible': 2
                        }
                    },

                    codeMirror: CodeMirror,
                    heightMin: 240,
                    imageUploadURL: process.env.REACT_APP_FROALA_EDITOR_IMAGE
                    , key: "9KH3cA4D3D3B2B2E3zfldceF6g1oB-8qezA-16zA1bD-8ypH2iisbB3H3E2A14A19B7C4C6D3D2==",
                    // events: {
                    //     'initialized': (e, edt) => {
                    //         setState(props.description)
                    //         console.log(edt)
                    //     }
                    // }
                    // imageUploadParams: {
                    //   eventId: event.id
                    // }
                }}
                onModelChange={e => props.setDescription(e)}
                model={props.description}
            />
            {/* <FroalaEditorView model={props.description} /> */}
        </React.Fragment>
    )
}

export default FroalaEditorComponent