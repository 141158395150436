import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import ThaiRunPhoto from '../images/thai_run.png'

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
    },
});

class LoginModal extends React.Component {
    state = {
        // open: this.props.modal,
        fullWidth: true,
        maxWidth: 'sm',
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.history.goBack();
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };
    // UNSAFE_componentWillReceiveProps(props) {
    //     this.setState({
    //         open: props.modal
    //     })
    // }

    render() {
        const { classes } = this.props;


        return (
            <React.Fragment>
                {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    Open max-width dialog
        </Button> */}
                <Dialog
                    fullScreen={this.props.fullScreen}
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                    open={this.props.modal}
                    onClose={this.handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">
                        <Typography variant="h2" component="h2" align="center">
                            Login
                    </Typography></DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                        </DialogContentText>
                        <Grid
                            container
                            justify={"center"}
                            alignItems={"center"}
                            direction={"column"}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className={"DL01-GridItem -form"}
                        >
                            <form>
                                <div align="center">
                                    <img style={{ width: '60%', height: '200px' }}
                                        src={ThaiRunPhoto}
                                    />
                                </div>
                                <Typography color={"textSecondary"}>
                                    We provide the best tool
                        </Typography>
                                <TextField
                                    fullWidth
                                    label={"Username"}
                                    margin={"normal"}
                                    variant="filled"
                                />
                                <TextField
                                    fullWidth
                                    label={"Password"}
                                    margin={"normal"}
                                    variant="filled"
                                />
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={<Checkbox value="checkedC" />}
                                        label="Remember Me"
                                    />
                                </FormControl>
                                <FormControl margin={"normal"} fullWidth>
                                    <Button fullWidth variant={"contained"} color={"primary"}>
                                        Log in
                        </Button>
                                </FormControl>
                                <Typography>
                                    Don't have an account ? <Link to={`${this.props.location.pathname}?modal=signup`}>Sign Up Now</Link>
                                </Typography>
                            </form>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
            </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

LoginModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles), withMobileDialog({ breakpoint: 'xs' }))(LoginModal);
