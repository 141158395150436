import gql from 'graphql-tag';

const EventDetailQuery = gql`
  query getEventDetail($eventId : String) {
    events(where : {
      eventId : {
        equals : $eventId
      }
    },first: 1){
      eventId
      eventNameTH
      eventNameEN
      latitude
      longtitude
      priceStart
      priceEnd
      eventDateStart
      eventDateEnd
      coverPhotoUrl
      raceType
      bookableDateStart
      bookableDateEnd
      slug
      distance{
        distanceTypeId
        distance
      }
      descriptionTH
      descriptionEN
      day
      week
      month
      provinceEN
      keyword
      organizer
      linkUrl
      isPublic
      howManyYear
      thisYearth
      isRecommend
      thumbnailPhotoUrl
      locationTextShowTH
    }
  }
`;

export default EventDetailQuery