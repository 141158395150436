import React from 'react';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.8),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 1),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%'
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        height: '30px',
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
});

class SearchBar extends React.Component {
    state = {
        text: ''
    };



    handleSubmit = (e) => {
        e.preventDefault()
        console.log(this.state.text);
    }

    handleChange = (event) => {
        console.log(event.target.value);
        this.setState({ text: event.target.value });
    }

    render() {

        const { classes } = this.props;


        return (
            <div className={classes.root}>
                <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={0} alignItems="center">
                        <Grid item xs={10}>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase onChange={this.handleChange} style={{ borderRadius: '0px' }}
                                    value={this.state.text}
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                />

                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div >
                                <Button type="submit" style={{ backgroundColor: 'green', width: '100%', borderRadius: '0px', height: '46px', }}>
                                    Search
                    </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

SearchBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchBar);
