import React from 'react';
// import { graphql } from 'react-apollo'
import queryString from 'query-string'
import { compose } from 'recompose';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import {
    withStyles
} from '@material-ui/core'



import { Root, Header, Nav, Content } from '../Layout';
import NavBar from '../components/NavBar';
//import eventList from '../components/eventList';

//import show_post from '../graphql/queries/show_post';
import ComponentHeader from '../components/ComponentHeader';
//import SearchBar from '../components/SearchBar';
//import ModalLogin from '../components/loginModal';
import LoginModal from '../components/LoginModal';
import SignupModal from '../components/SignupModal';
import EventModal from '../components/EventModal';

import MainPage from './MainPage';
import EventSearch from './EventSearch';
import CreateEventHook from './CreateEventHook';

import LoginPage from './LoginPage'
import EventList from './EventList'
import EditEvent from './EditEvent'

//import Test from '../images/thai_run.png'
import config from '../config';


// const styles = theme => ({
//     root: {
//         flexGrow: 1,
//     },
//     paper: {
//         padding: theme.spacing.unit * 2,
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//     },
//     header: {
//         marginRight: '0 px',
//         textAlign: 'right'
//     }
// });


// const styles2 = theme => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'space-around',
//         overflow: 'hidden',
//         backgroundColor: theme.palette.background.paper,
//     },
//     gridList: {
//         width: 500,
//         height: 450,
//     },
//     icon: {
//         color: 'rgba(255, 255, 255, 0.54)',
//     },
// });

const stylesCard = theme => ({
    card: {
        //minWidth: 275,
        width: '100%',
        height: '100%',
        margin: '0px 0px 0px 0px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 1,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
    },
    image: {
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 500,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
});



class App extends React.Component {

    state = { currentPath: this.props.location.pathname, loginModal: false, navBarStatus: 0 };


    constructor(props) {

        super(props);
        this.getNavStatus = this.getNavStatus.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate() {


    }


    getNavStatus = (status) => {
        this.setState({ navBarStatus: status });
        //console.log("statusApp", this.state.navBarStatus);

    }

    LoginModalCheck = (props) => {
        //console.log('checking..')
        // console.log(this.props.location.pathname);
        const query = queryString.parse(this.props.location.search);
        //console.log({ query })
        if (query.modal == 'logingg') {
            return true;
        }
        else {
            const modalOpen = false;
            return false;
        }

    }


    SignupModalCheck = (props) => {
        //console.log('checking..')
        // console.log(this.props.location.pathname);
        const query = queryString.parse(this.props.location.search);
        //console.log({ query })
        if (query.modal == 'signup') {
            return true;
        }
        else {
            const modalOpen = false;
            return false;
        }

    }

    EventModalCheck = (props) => {
        //console.log('checking..')
        // console.log(this.props.location.pathname);
        const query = queryString.parse(this.props.location.search);
        //console.log({ query })
        if (query.Eventmodal) {
            //console.log(query.Eventmodal);
            return true;
        }
        else {
            const modalOpen = false;
            return false;
        }

    }






    setCurrentPath = (props) => {

        return this.props.location.pathname;
    }



    componentWillReceiveProps() {
        // console.log('cwrp')
    }

    // shouldComponentUpdate(nextProps) {
    //     // const nextQuery = queryString.parse(nextProps.location.search);
    //     // const query = queryString.parse(this.props.location.search);
    //     // console.log({ nextQuery, query })
    //     // return nextQuery.EventModal !== query.EventModal
    //     // return this.EventModalCheck(props)
    // }


    render() {
        const { classes } = this.props;
        //console.log("statusMain", this.state.navBarStatus);
        //console.log('render..')
        //console.log(classes);
        //console.log(this.state);
        //console.log(this.props.users);
        //console.log(this.props.location.pathname);
        // //console.log(queryString.parse(this.props.location.search));
        // const query = queryString.parse(this.props.location.search);
        // if (query.modal == 'login') {
        //     const modalOpen = true;
        //     console.log("true");
        // }
        // else {
        //     const modalOpen = false;
        //     console.log("false");
        // }


        return (
            <Root config={config} style={{ minHeight: "100vh" }}>
                <CssBaseline />
                <BrowserRouter>
                    <Header
                        menuIcon={{
                            inactive: <MenuIcon />,
                            active: <ArrowBackIosIcon />
                        }}
                    >
                        <ComponentHeader />
                        {/* header goes here */}

                    </Header>
                    <Nav
                        collapsedIcon={{
                            inactive: <ArrowBackIosIcon />,
                            active: <ArrowForwardIosIcon />
                        }}
                        header={
                            // you can provide fixed header inside nav
                            // change null to some react element
                            ctx => null
                        }
                    >

                        {/* nav goes here */}
                        <Switch>
                            <Route exact path="/" render={() => <NavBar status={0} />} />
                            <Route path="/eventList" render={() => <NavBar status={2} />} />
                            <Route path="/createevent" render={() => <NavBar status={1} />} />
                            <Route render={() => <NavBar status={10} />} />
                        </Switch>
                        {/* <NavBar status={this.state.navBarStatus} /> */}

                    </Nav>
                    <Content style={{ backgroundColor: '#CCCCCC' }}>
                        {/* content goes here */}



                        <Switch>
                            <Route exact path="/" render={() => <MainPage action={this.getNavStatus} />} />
                            <Route path="/eventList" component={EventList} />
                            <Route path="/events" render={() => <EventSearch action={this.getNavStatus} />} />
                            <Route path="/organiser" component={MainPage} />
                            <Route path="/createevent" render={() => <CreateEventHook action={this.getNavStatus} />} />
                            <Route path="/login" render={() => <LoginPage />} />
                            <Route path="/editEvent/:eventId" component={EditEvent} />

                        </Switch>
                        {/* <Route render={props => {
                            const query = queryString.parse(props.location.search);
                            const modal = !!query.Eventmodal
                            return <EventModal modal={modal} />
                        }} />
                        <Route render={props => {
                            const query = queryString.parse(props.location.search);
                            //console.log({ query })
                            if (query.modal == 'login') {
                                return <LoginModal modal={true} />
                            }
                            else {
                                return <LoginModal modal={false} />
                            }

                        }} />
                        <Route render={props => {
                            const query = queryString.parse(props.location.search);
                            //console.log({ query })
                            if (query.modal == 'signup') {
                                return <SignupModal modal={true} />
                            }
                            else {
                                return <SignupModal modal={false} />
                            }

                        }} /> */}










                        <p style={{ marginTop: '20px' }} />
                    </Content>
                    {/* <Footer></Footer> */}
                    {/* <LoginModal modal={this.LoginModalCheck()} /> */}
                    {/* <SignupModal modal={this.SignupModalCheck()} /> */}
                    {/* <EventModal modal={this.EventModalCheck()} /> */}
                </BrowserRouter>
            </Root >
        );
    }
}
export default compose(
    // graphql(show_post, { name: 'posts' }),
    withStyles(stylesCard, { withTheme: true }),
    withRouter
)(App)