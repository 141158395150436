import React from 'react';
import { withRouter } from 'react-router';

import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import DialogContentText from '@material-ui/core/DialogContentText';



import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';



import ThaiRunPhoto from '../images/thai_run.png'

import GoogleMapOneMarker from './GoogleMapOneMarker';

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
    },
});

class EventModal extends React.Component {
    state = {
        // open: this.props.modal,
        fullWidth: true,
        maxWidth: 'md',
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.history.push(`${this.props.location.pathname}`)
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };
    // UNSAFE_componentWillReceiveProps(props) {
    //     this.setState({
    //         open: props.modal
    //     })
    // }

    render() {
        const { classes } = this.props;


        return (
            <React.Fragment>
                {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    Open max-width dialog
        </Button> */}
                <Dialog
                    scroll="body"
                    fullScreen={this.props.fullScreen}
                    fullWidth={this.state.fullWidth}
                    maxWidth={this.state.maxWidth}
                    open={this.props.modal}
                    onClose={this.handleClose}
                    aria-labelledby="max-width-dialog-title"
                >

                    <div align="center">
                        <img style={{ width: '100%', height: '300px' }}
                            src={ThaiRunPhoto}
                        />
                    </div>
                    <DialogContentText>
                        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <div style={{ fontSize: 25 }}>
                                Thai RUn Bangkok Festival - 2019
                            </div>
                            <p style={{ marginTop: 2 }}></p>
                            <div style={{ fontSize: 16 }}>
                                Saturday 20 June 2019
                            </div>
                            <p style={{ marginTop: 2 }}></p>
                            <div style={{ fontSize: 20 }}>ORGANISER'S DESCRIPTION</div>
                            <p style={{ marginTop: 2 }}></p>
                            <div>
                                The Salomon Trail Running Festivals will be held in three specially
                                 selected locations across the UK:
                                  Edale in Peak District, Holyrood in Edinburgh and Box Hill near London.

                                Whether you are a seasoned road runner,
                                 or eager to start running, these activity-filled
                                  days will help you on your trail running journey.
                                   Our festivals are the perfect opportunity to get
                                    involved in the trail running community, enjoy the
                                     freedom nature offers, and find your local trails.
                            </div>

                        </div>
                        <p style={{ marginTop: 2 }}></p>
                        <GoogleMapOneMarker lat={12} lng={12} />
                    </DialogContentText>
                    <Grid
                        container
                        justify={"center"}
                        alignItems={"center"}
                        direction={"column"}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >

                    </Grid>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Close
            </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

EventModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles), withMobileDialog({ breakpoint: 'xs' }))(EventModal);
