import React from 'react';
import { Link } from 'react-router-dom';
//import { hashHistory } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import { withRouter } from 'react-router'
import ListIcon from '@material-ui/icons/List';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import {
    Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ExitToApp as LogoutIcon, Home as HomeIcon, Assignment as SellReportIcon,
} from '@material-ui/icons'

import Test from '../images/thai_run.png'
import '../css/navBar.css'

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 500,
        backgroundColor: theme.palette.background.paper,
    },
});

class NavBar extends React.Component {
    state = {
        selectedIndex: this.props.status,
    };

    componentDidUpdate() {

    }

    componentDidMount() {
        //this.setState({ selectedIndex: this.props.status })
        //console.log("statffffus", this.state.selectedIndex);
    }


    handleListItemClick = (event, index) => {

        if (index == 0) {
            this.setState({ selectedIndex: index });
            this.props.history.push("/");
        }
        if (index == 1) {
            // console.dir(this.props.location.pathname)
            this.setState({ selectedIndex: index });
            //this.props.history.push(`${this.props.location.pathname}?modal=login`)
            this.props.history.push("/createevent");
            // hashHistory.push(`${this.props.location}?modal=login`);
        }
        if (index == 2) {
            // console.dir(this.props.location.pathname)
            this.setState({ selectedIndex: index });
            this.props.history.push("/eventList");
            // hashHistory.push(`${this.props.location}?modal=login`);
        }
    };




    render() {
        const { classes } = this.props;
        // console.log("render", this.state.selectedIndex);
        // console.log('here', this.props.location);

        return (
            <div className={classes.root}>
                <img className="mainImage" src={Test} />
                <List component="nav">
                    <ListItem
                        button
                        selected={this.state.selectedIndex === 0}
                        onClick={event => this.handleListItemClick(event, 0)}
                    >
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="DashBoard" />
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        selected={this.state.selectedIndex === 1}
                        onClick={event => this.handleListItemClick(event, 1)}
                    >
                        <ListItemIcon>
                            <AddToPhotosIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create Event" />
                    </ListItem>
                    <Divider />
                    <ListItem
                        button
                        selected={this.state.selectedIndex === 2}
                        onClick={event => this.handleListItemClick(event, 2)}
                    >
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary="Event List" />
                    </ListItem>
                    {/* <ListItem
                        button
                        selected={this.state.selectedIndex === 2}
                        onClick={event => this.handleListItemClick(event, 2)}
                    >
                        <ListItemIcon>
                            <DraftsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Organiser" />
                    </ListItem>
                    <Divider /> */}

                </List>

            </div>
        );
    }
}

NavBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(NavBar));
