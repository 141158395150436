import React from 'react';
import { withRouter } from 'react-router'
import Shoe from '../images/trail-running-shoe.svg';
import fetch from 'isomorphic-fetch';
import { compose, withProps, withHandlers } from 'recompose';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';



class GoogleMapCluster extends React.PureComponent {
    componentWillMount() {
        this.setState({ markers: [], loading: false })
    }

    componentDidMount() {
        const url = [
            // Length issue
            `https://gist.githubusercontent.com`,
            `/farrrr/dfda7dd7fccfec5474d3`,
            `/raw/758852bbc1979f6c4522ab4e92d1c92cba8fb0dc/data.json`
        ].join("")
        this.setState({ loading: true })
        fetch(url)
            .then(res => res.json())
            .then(data => {
                this.setState({ markers: data.photos, loading: false });
            });
    }

    handleMarkerClick = (e, id) => {
        //console.log(e.latLng.lat());
        //console.log(e);
        this.props.history.push(`${this.props.location.pathname}?Eventmodal=${id}`)
    }

    render() {
        //console.log(this.props.test);
        if (this.state.loading) {
            return 'Loading ...'
        }
        return (
            <GoogleMap
                defaultZoom={5}
                defaultCenter={{ lat: 13.03887, lng: 101.490104 }}
            >

                <MarkerClusterer
                    onClick={this.props.onMarkerClustererClick}
                    averageCenter
                    enableRetinaIcons
                    gridSize={60}
                >
                    {this.state.markers.map(marker => (
                        <Marker
                            onClick={(e) => this.handleMarkerClick(e, marker.photo_id)}
                            key={marker.photo_id}
                            position={{ lat: marker.latitude, lng: marker.longitude }}
                            icon={{
                                url: Shoe,
                                scaledSize: new window.google.maps.Size(30, 30)
                            }}

                        />

                    ))}
                </MarkerClusterer>
            </GoogleMap>
        )
    }
}

export default compose(withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB6LQ-qWuAuN2nV5wfxBW3jkVgBRppGKMY&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
}),
    withHandlers({
        onMarkerClustererClick: () => (markerClusterer) => {
            const clickedMarkers = markerClusterer.getMarkers()
            // console.log(`Current clicked markers length: ${clickedMarkers.length}`)
            //console.log(clickedMarkers)
        }
    }),
    withScriptjs,
    withGoogleMap,
    withRouter
)(GoogleMapCluster);