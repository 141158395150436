import gql from 'graphql-tag';

const ListAllEventQuery = gql`
  query getListEvent {
    events(orderBy: {
        eventNameTH : asc
    }){
      eventId
      isPublic
      eventNameTH
      eventDateStart
      raceType
      slug
      lastUpdateTime
    #   lastUpdateAdmin{
    #       email
    #   }

    }
  }
`;

export default ListAllEventQuery